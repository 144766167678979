import React from 'react';
import SpeedTest from './SpeedTest';
import './index.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <SpeedTest />
      </header>
    </div>
  );
}

export default App;
